.page-not-found section {
  display: flex;
}

.project-not-found-wrap {
  flex: 1;
  display: flex;
  align-items: center;
}

.generic-not-found {
    flex:1;
    margin-bottom: $unit * 9;
    @include breakpoint($med){ margin-bottom: $unit-8 }
}
.project-not-found {
    flex:1;
    @include breakpoint($small){ margin-bottom: $unit * 9 }
    @include breakpoint($med){ margin-bottom: $unit-8 }
}

.project-not-found, .generic-not-found {
    vertical-align:middle;
    display: table-cell;
    // to center between bar and footer: height = page - header+ - footer - header+
    // height: calc(100vh - #{$unit * 10} - #{$unit * 16} - #{$unit * 10});

    // @include breakpoint($small){ height: calc(100vh - #{$unit * 10} - #{$unit-9} - #{$unit * 10}); }
    // @include breakpoint($med){ height: calc(100vh - #{$unit-8} - #{$unit-9} - #{$unit-8}); }

    .h1 {
        color: $grey_dark;

        a {
            @include link--secondary;
        }
    }
}

.project-not-found {
    .thumbnail .thumbnail-title {
        @include breakpoint($small){ height: auto; }
    }
}
