.feed-years { }

.feed-year {

  .article-feed & { padding-top: $unit; }
  .article &      { padding-top: $unit; }

  &:not(:last-child) { border-bottom: 1px solid $grey-medium; }

  .articles &:not(:first-child) { padding-top: $unit; }
  .articles &:first-child .feed-posts {

    @include breakpoint($med) {
      margin-top: -$unit-3;
    }
  }

}

.feed-year-title {
  margin-bottom: $unit-3 !important;
}

.feed-posts {}

.grid-sizer {
  @include breakpoint($small) {
    width:  50%;
  }
}

.feed-post {
  padding-bottom: $unit-5;

  @include breakpoint($small) {
    padding-right: $unit-2;
    width: 50%;
  }

  date {
    display: block;
    // margin-bottom: $unit-15;
    color: $grey-dark;
  }

  .post-img {
    margin-bottom: $unit;

    img {
      position: absolute;
    }
  }
}

.feed-post__title {
  display: inline-block;
  background-image: none;
}

.feed-post-more {
  display: inline;
  top: -3px !important;
  color: $grey-dark !important;

  &:hover {
    background-image: none !important;
    color: $black !important;
  }
}

.article {
  date {
    color: $grey-dark;
  }

  @include breakpoint($med) {
    margin-bottom: $unit-4;
  }

  .more-full {

    @include breakpoint($small-only) {
      margin-bottom: $unit-3;
    }
  }

  .article-content {
    margin-bottom: $unit-3;
    margin-top: -4px;

    @include breakpoint($med) {
      padding-right: $unit-2;
    }
  }
}

.article-title {
  margin-bottom: 14px;

  @include breakpoint($med) {
    padding-right: $unit-2;
  }
}

.article-images {
  margin-bottom: $unit-2;

  img {
    position: absolute;
  }
  .img:not(:last-child) {
    margin-bottom: 1px;
  }
}

.article-date--top {
  display: none;

  @include breakpoint($med) {
    display: block;
  }
}

.article-date--bottom {

  @include breakpoint($med) {
    display: none;
  }
}
