.their-words section 
  .content {
    margin-bottom: $unit-2;

    @include breakpoint($med) { margin-bottom: $unit-4; }
  }

  .container {
    > p,
    > ul {
      @include breakpoint($med) {
        @include col-half(false);
      }
    }

    > p {
      margin-bottom: $unit-5;

      @include breakpoint($small) { margin-bottom: $unit-5; }
      @include breakpoint($med) { padding-right: 8.33%; } // 16.66 of column, 8.33% of container
    }
}