// Typography

.current {
  color: $red;
}

// Images

.images-2up {
  @extend %row;

  > span {
    padding-bottom: 0 !important;
    height: auto !important;
    &:not(:last-child) {
      .post-img {
        @include breakpoint($small-only) {
          margin-bottom: 1px;
        }
      } 
    }

    @include breakpoint($small) {
      @include col-half(false);
    }
  }
}

.bg-whiteWarm {
  background-color: $white-warm;
}

.cf {clear:both}

.sm-cf {
  @include breakpoint($small) {
    clear: both;
  }
}

.md-cf-off {
  @include breakpoint($med) {
    clear: none;
  }
}

%clearfix:before,
%clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

%clearfix:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
%clearfix {
    *zoom: 1;
}

.box { // for testing
  background: #ccc;
  width: 100%;
  height: 20px;
  margin-bottom: 12px;
}

.hide {
  display: none;
}

.hidden-mobile {
  display: none;

  @include breakpoint($small) {
    display: block;
  }
}

.hidden-break {
  @include breakpoint($small) {
    display: none;
  }
}

.border-top,
%border-top {
  position: relative;
  // border-top: 1px solid transparent;

  &:before {
    @include pseudo;
    left: 0;
    right: 1px; // might break something?
    top: 0;
    height: 1px;
    background: $grey_medium;
  }
}

.box { // for testing
  background: #ccc;
  width: 100%;
  height: 20px;
  margin-bottom: 12px;
}

.inline { display: inline !important; }

