.all-projects {
	@include breakpoint($small) {
		footer .row:before {
			margin-right: $unit-2;
		}
	}
}

.projects-list {
	font-size: $h4;
	line-height: $h4-lh;
	padding-bottom: $unit-4;
	column-gap: 0;

	@include breakpoint($small) {
		columns: 2;
	}

	@include breakpoint($med) {
		columns: 3;
	}
}

.projects-list__year {
	break-inside: avoid;
	padding-top: 4px;
	padding-bottom: $unit-4 - $unit-half - 1px;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 4px;
		left: 0;
		width: 100%;
		border-top: 1px solid $grey_medium;
	}

	@include breakpoint($small) {
		margin-right: $unit-2;
	}
}

.projects-list__year-heading {
	margin-top: -2px;
	font-size: $h2;
	line-height: $h2-lh; //1;
	font-family: $font-NHG--med;
	letter-spacing: -(2em/32); // 1px, scalable with font size
	margin-bottom: 5px;
}

.projects-list__projects {
	a {
		color: $black;
	    @include link;
	}
}