.store-description {
    border-top: 1px solid $grey_medium;
    padding-top: $unit - 1px;
    min-height: $unit-8;
    margin-bottom: $unit-4;

    p {
        @include h4;
        color: $black;

        @include breakpoint($small) {
            @include col-half(false);
        }

        &:last-child {
            margin-bottom: 0;
            @include breakpoint($small) {
                max-width: $max-text-width;
            }
        }
    }

    p:first-of-type {
        @include h2;

        margin-bottom: $unit;
    }
}

.product {
    margin-bottom: $unit * 3;
    @include breakpoint($small) { margin-bottom: $unit * 11; }
    @include breakpoint($med) { margin-bottom: $unit * 10; }
  
    .container {
        display: flex; flex-direction: column;
        @include breakpoint($small) { display: block; }
    }
  
    .product-info {
        margin-bottom: $unit-3;
        order: 2;

        @include breakpoint($small) {
            border-top: 1px solid $grey_medium;
            padding-top: $unit - 1px;
            min-height: $unit-10;
            margin-bottom: $unit-2;            
        }
        
        @include breakpoint($med) {
        }
    }
      
    .product-title {
        @include breakpoint($small) { 
            margin-bottom: $unit; 
            padding-right: 8.33%;
        }
    }
  
    .product-download {
        a {
            @include link--tertiary;
        }
    }
  
    .product-gallery { order: 1; }
    .product-detail { order: 3; }
}

.product-detail {
    max-width: $max-text-width;
}