.js-more { // button
  position: relative;
  @include h4;

  display: block;
  margin-top: -$unit; margin-left: 0;
  
  color: $black;

  &:before {
    content: '';
    position: absolute;
    top:    -$unit;
    left:   -$unit;
    right:  -$unit;
    bottom: -$unit;
  }

  &:hover,
  &:active {
    color: $red;
  }
}

.more-short {
  @include breakpoint($small) {
    display: none;
  }
}

.more-full {
  @include breakpoint($mobile) {
    display: none;
  }
}
