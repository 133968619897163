.project-detail {


  img {
    margin-bottom: 1px;
  }
}

.project-detail .project-info {
  border-top: 1px solid $grey_medium;
  padding-top: $unit-half;

  @include breakpoint($small) {
    width: 100%;
  }

  @include breakpoint($med) {
    width: 50%;
  }
}

// project detail

.project-detail .content {
  @include breakpoint($med) {
    @include col-two-thirds(false);
  }
}

.project-description {
  margin-bottom: $unit-4;
  // margin-top: -4px;

  @include breakpoint($small) {
    max-width: 38em;
  }

  @include breakpoint($med) {
    max-width: $max-text-width;
  }

  p, ul, ol {
    margin-bottom: $unit;
    position: static;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  .js-more {
    margin-top: 0;
    top: 0;
  }

  // @include breakpoint($large) {
  //   margin-bottom: $unit-4;
  // }

  // @include breakpoint($med) {
  //   & > p:first-child {
  //     display: none;
  //   }
  // }
}

.project-images {
  clear: both;
  .lazy:last-child img{
    margin-bottom: 0;
  }
}

.more-projects {
  .h2 {
    margin-bottom: $unit/2;
  }
}

.more {
  svg {
    width: 100%;
    height: 43px;
    display: block;
    margin-bottom: 1px;

    @include breakpoint($large) {
      height: 51px;
    }
  }

  .thumbnail:last-child {
    display: none;
    @include breakpoint($med) {display: block;}
  }
}


