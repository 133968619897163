.thumbnails {
  @extend %row;
  width: 100%;
}

.thumbnail {

  &:hover {
    a { color: $red; }
    .thumb img.lazyloaded { transform: scale3d(1.05,1.05,1); }
  } 

  @include breakpoint($small) {
    @include col-half(false);
  }

  .home & { // two col thumbnails only on home
    @include col-half;
    margin-bottom: 1px;

    // hide images on mobile hacky

    &:nth-child(5),
    &:nth-child(6) {
      display: none;
    }

    @include breakpoint($small) {
      @include col-half(false);
    }
    @include breakpoint($med) {
      display: block !important;
      @include col-third(false);
    }

    span {margin: 0;}
  }

  a { // overwrite default link color
    display: block;
    color: $black;
  }

  img {
    display: block;
  }

  .thumbnail-title {
    padding-top: $unit-half;
    height: $unit-6;

    @include breakpoint($small) { height: $unit-6; padding-right: 16.66% }
    @include breakpoint($med) { height: $unit-8; }
    @include breakpoint($large) { height: $unit-8; }
  }
}

.img {
  display: block;
  position: relative;
  width: 100%;

  &.detail {
    margin-bottom: 1px;
    overflow: hidden;
  }
  &.thumb {
    .lazycont {
      padding-bottom: 56.47%;
      height: 0;
      overflow: hidden;
    }
  }

  .lazycont {
    display: block;
    position: relative;
    width: 100%;

    img {
      opacity: 0;

      &.lazyloaded {
        transition: opacity 200ms 250ms ease-in-out, transform 250ms cubic-bezier(0.19, 1, 0.22, 1);
        opacity: 1;
        transform: scale3d(1,1,1);
      }
    }
  }
}

.img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
