// @include grid-toggle;

.grid {
  min-height: 500px;
  position: absolute;
  left:  12px;
  right: 12px;
  margin: 0 auto;
  pointer-events: none;

  @include breakpoint($small) {max-width: 768px;}
  @include breakpoint($med)   {max-width: 1130px;}

  @include breakpoint ($large) {
    max-width: 2184px;
    left:  144px;
    right: 144px;
  }
}

// row

%row,
.row { // required to start a grid
  // overflow: hidden;
  &:after{
    content: "";
    display: table;
    clear: both;
  }

  @include breakpoint($small) {
    margin-right: -$gutter1;
  }

  @include breakpoint($large) {
    margin-right: -$gutter2;
  }
}

// col setup

@mixin col {
  //float: left;
  display: inline-block;
  vertical-align: top;
  padding-right: $gutter1;
  float: left;
  @include breakpoint($large) {
    padding-right: $gutter2;
  }
}

%col-sixth,
%col-fourth,
%col-third,
%col-half,
%col-two-thirds {
  @include col;
}

$totalColumns: 12;
$columnWidth: 100 / 12 * 1%;

@mixin col-1  { @include col; width: $columnWidth; }
@mixin col-2  { @include col; width: $columnWidth * 2; }
@mixin col-3  { @include col; width: 25%; }
@mixin col-4  { @include col; width: $columnWidth * 4; }
@mixin col-5  { @include col; width: $columnWidth * 5; }
@mixin col-6  { @include col; width: 50%; }
@mixin col-7  { @include col; width: $columnWidth * 7; }
@mixin col-8  { @include col; width: $columnWidth * 8; }
@mixin col-9  { @include col; width: 75%; }
@mixin col-10 { @include col; width: $columnWidth * 10; }
@mixin col-11 { @include col; width: $columnWidth * 11; }
@mixin col-12 { @include col; width: 100%; }

.col-1  { @include col-1; }
.col-2  { @include col-2; }
.col-3  { @include col-3; }
.col-4  { @include col-4; }
.col-5  { @include col-5; }
.col-6  { @include col-6; }
.col-7  { @include col-7; }
.col-8  { @include col-8; }
.col-9  { @include col-9; }
.col-10 { @include col-10; }
.col-11 { @include col-11; }
.col-12 { @include col-12; }

.sm-col-1  { @include breakpoint($small) { @include col-1; } }
.sm-col-2  { @include breakpoint($small) { @include col-2; } }
.sm-col-3  { @include breakpoint($small) { @include col-3; } }
.sm-col-4  { @include breakpoint($small) { @include col-4; } }
.sm-col-5  { @include breakpoint($small) { @include col-5; } }
.sm-col-6  { @include breakpoint($small) { @include col-6; } }
.sm-col-7  { @include breakpoint($small) { @include col-7; } }
.sm-col-8  { @include breakpoint($small) { @include col-8; } }
.sm-col-9  { @include breakpoint($small) { @include col-9; } }
.sm-col-10 { @include breakpoint($small) { @include col-10; } }
.sm-col-11 { @include breakpoint($small) { @include col-11; } }
.sm-col-12 { @include breakpoint($small) { @include col-12; } }

.med-col-1  { @include breakpoint($med) { @include col-1; } }
.med-col-2  { @include breakpoint($med) { @include col-2; } }
.med-col-3  { @include breakpoint($med) { @include col-3; } }
.med-col-4  { @include breakpoint($med) { @include col-4; } }
.med-col-5  { @include breakpoint($med) { @include col-5; } }
.med-col-6  { @include breakpoint($med) { @include col-6; } }
.med-col-7  { @include breakpoint($med) { @include col-7; } }
.med-col-8  { @include breakpoint($med) { @include col-8; } }
.med-col-9  { @include breakpoint($med) { @include col-9; } }
.med-col-10 { @include breakpoint($med) { @include col-10; } }
.med-col-11 { @include breakpoint($med) { @include col-11; } }
.med-col-12 { @include breakpoint($med) { @include col-12; } }

.lrg-col-1  { @include breakpoint($large) { @include col-1; } }
.lrg-col-2  { @include breakpoint($large) { @include col-2; } }
.lrg-col-3  { @include breakpoint($large) { @include col-3; } }
.lrg-col-4  { @include breakpoint($large) { @include col-4; } }
.lrg-col-5  { @include breakpoint($large) { @include col-5; } }
.lrg-col-6  { @include breakpoint($large) { @include col-6; } }
.lrg-col-7  { @include breakpoint($large) { @include col-7; } }
.lrg-col-8  { @include breakpoint($large) { @include col-8; } }
.lrg-col-9  { @include breakpoint($large) { @include col-9; } }
.lrg-col-10 { @include breakpoint($large) { @include col-10; } }
.lrg-col-11 { @include breakpoint($large) { @include col-11; } }
.lrg-col-12 { @include breakpoint($large) { @include col-12; } }

@mixin offset-col-1  { @include col; margin-left: $columnWidth; }
@mixin offset-col-2  { @include col; margin-left: $columnWidth * 2; }
@mixin offset-col-3  { @include col; margin-left: 25%; }
@mixin offset-col-4  { @include col; margin-left: $columnWidth * 4; }
@mixin offset-col-5  { @include col; margin-left: $columnWidth * 5; }
@mixin offset-col-6  { @include col; margin-left: 50%; }
@mixin offset-col-7  { @include col; margin-left: $columnWidth * 7; }
@mixin offset-col-8  { @include col; margin-left: $columnWidth * 8; }
@mixin offset-col-9  { @include col; margin-left: 75%; }
@mixin offset-col-10 { @include col; margin-left: $columnWidth * 10; }
@mixin offset-col-11 { @include col; margin-left: $columnWidth * 11; }

.offset-1   { @include offset-col-1; }
.offset-2   { @include offset-col-2; }
.offset-3   { @include offset-col-3; }
.offset-4   { @include offset-col-4; }
.offset-5   { @include offset-col-5; }
.offset-6   { @include offset-col-6; }
.offset-7   { @include offset-col-7; }
.offset-8   { @include offset-col-8; }
.offset-9   { @include offset-col-9; }
.offset-10  { @include offset-col-10; }
.offset-11  { @include offset-col-11; }

.sm-offset-1   { @include breakpoint($small) { @include offset-col-1; } }
.sm-offset-2   { @include breakpoint($small) { @include offset-col-2; } }
.sm-offset-3   { @include breakpoint($small) { @include offset-col-3; } }
.sm-offset-4   { @include breakpoint($small) { @include offset-col-4; } }
.sm-offset-5   { @include breakpoint($small) { @include offset-col-5; } }
.sm-offset-6   { @include breakpoint($small) { @include offset-col-6; } }
.sm-offset-7   { @include breakpoint($small) { @include offset-col-7; } }
.sm-offset-8   { @include breakpoint($small) { @include offset-col-8; } }
.sm-offset-9   { @include breakpoint($small) { @include offset-col-9; } }
.sm-offset-10  { @include breakpoint($small) { @include offset-col-10; } }
.sm-offset-11  { @include breakpoint($small) { @include offset-col-11; } }

.med-offset-1   { @include breakpoint($med) { @include offset-col-1; } }
.med-offset-2   { @include breakpoint($med) { @include offset-col-2; } }
.med-offset-3   { @include breakpoint($med) { @include offset-col-3; } }
.med-offset-4   { @include breakpoint($med) { @include offset-col-4; } }
.med-offset-5   { @include breakpoint($med) { @include offset-col-5; } }
.med-offset-6   { @include breakpoint($med) { @include offset-col-6; } }
.med-offset-7   { @include breakpoint($med) { @include offset-col-7; } }
.med-offset-8   { @include breakpoint($med) { @include offset-col-8; } }
.med-offset-9   { @include breakpoint($med) { @include offset-col-9; } }
.med-offset-10  { @include breakpoint($med) { @include offset-col-10; } }
.med-offset-11  { @include breakpoint($med) { @include offset-col-11; } }

.lrg-offset-1   { @include breakpoint($large) { @include offset-col-1; } }
.lrg-offset-2   { @include breakpoint($large) { @include offset-col-2; } }
.lrg-offset-3   { @include breakpoint($large) { @include offset-col-3; } }
.lrg-offset-4   { @include breakpoint($large) { @include offset-col-4; } }
.lrg-offset-5   { @include breakpoint($large) { @include offset-col-5; } }
.lrg-offset-6   { @include breakpoint($large) { @include offset-col-6; } }
.lrg-offset-7   { @include breakpoint($large) { @include offset-col-7; } }
.lrg-offset-8   { @include breakpoint($large) { @include offset-col-8; } }
.lrg-offset-9   { @include breakpoint($large) { @include offset-col-9; } }
.lrg-offset-10  { @include breakpoint($large) { @include offset-col-10; } }
.lrg-offset-11  { @include breakpoint($large) { @include offset-col-11; } }


// SIXTH

@mixin col-sixth($extend: true) {
  @if $extend {@extend %col-third;}
  @else {
    @include col;
    width: 16.666667%;}
}

%col-sixth {@include col-sixth(false);}

// FOURTH

@mixin col-fourth($extend: true) {
  @if $extend {@extend %col-fourth;}
  @else {
    @include col;
    width: 25%;}
}

%col-fourth {@include col-fourth(false);}

// THIRD

@mixin col-third($extend: true) {
  @if $extend {@extend %col-third;}
  @else {
    @include col;
    width: 33.333333%;}
}

%col-third {@include col-third(false);}

// HALF

@mixin col-half($extend: true) {
  @if $extend {@extend %col-half;}
  @else {
    @include col;
    width: 50%;}
}

%col-half {@include col-half(false);}

// TWO THIRDS

@mixin col-two-thirds($extend: true) {
  @if $extend {@extend %col-two-thirds;}
  @else {
    @include col;
    width: 66.666666%;}
}

%col-two-thirds {@include col-two-thirds(false);}

// THREE QUARTER

@mixin col-three-qtr($extend: true) {
  @if $extend {@extend %col-three-qtr;}
  @else {
    @include col;
    width: 75%;}
}

%col-three-qtr {@include col-half(false);}

// FIVE SIXTH

@mixin col-five-sixth($extend: true) {
  @if $extend {@extend %col-five-sixth;}
  @else {
    @include col;
    width: 86.333335%;}
}

%col-five-sixth {@include col-two-thirds(false);}

// grid classes

.sm-col-half {
  @include breakpoint($small) {
    @include col-half(false);
  }
}

.sm-col-qtr {
  @include breakpoint($small) {
    @include col-fourth(false);
  }
}

.sm-col-third {
  @include breakpoint($small) {
    @include col-third(false);
  }
}

.sm-offset-half {
  @include breakpoint($small) {
    @include col;
    margin-left: 50%;
  }
}

.med-col-half {
  @include breakpoint($med) {
    @include col-half(false);
  }
}

.med-offset-half {
  @include col;

  @include breakpoint($med) {
    margin-left: 50%;
  }
}

.med-col-third {
  @include breakpoint($med) {
    @include col-third(false);
  }
}

.med-col-two-thirds {
  @include breakpoint($med) {
    @include col-two-thirds(false);
  }
}

.lg-col-half {
  @include breakpoint($large) {
    @include col-half(false);
  }
}

.lg-offset-half {
  @include breakpoint($large) {
    @include col;
    margin-left: 50%;
  }
}

// space classes

.mb-half    { margin-bottom: $unit }
.mb, .mb-1  { margin-bottom: $unit-2 }
.mb-1-5     { margin-bottom: $unit-3 }
.mb-2       { margin-bottom: $unit-4 }
.mb-2-5     { margin-bottom: $unit-5; }
.mb-3       { margin-bottom: $unit-6 }
.mb-3-5     { margin-bottom: $unit-7 }
.mb-4       { margin-bottom: $unit-8 }
.mb-half    { margin-bottom: $unit }

.mb-tight { margin-bottom: 0 !important; }

.pb       { padding-bottom: $unit }
.pb-2     { padding-bottom: $unit-2 }
.pb-3     { padding-bottom: $unit-3 }
.pb-4     { padding-bottom: $unit-4 }
.pb-half  { padding-bottom: $unit-half }

.pr       { padding-right: $unit      !important; }
.pr-2     { padding-right: $unit-2    !important; }
.pr-3     { padding-right: $unit-3    !important; }
.pr-4     { padding-right: $unit-4    !important; }
.pr-half  { padding-right: $unit-half !important; }

.pt, .pt-1 { padding-top: $unit !important; }

.no-margin { margin: 0 !important; }

.block  { display: block }
.ib     { display: inline-block }

.inline { display: inline; }
.inline-wrap {
  > * {
    display: inline;
  }
}
