$col-color: rgba(aqua, 0.35);
* {
  box-sizing: border-box;
}

html {
  $grid-color: rgba(red, 0.15);
  background-color: $white;

  &.gridShown {
    background: linear-gradient($grid-color, $grid-color 1px, transparent 1px);
    background-size: 1px $unit;
    background-position: 0 -1px;
  }
}

body {
  border-top: solid $black $body-top-border-height;
  -webkit-font-smoothing: antialiased !important;
  color: $black;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: $font-NHG--roman;
  min-height: 100vh;
}

header,
footer {
  a { color: $black; }
}

header{
  width: 100%;
  min-height: $unit-13 - $body-top-border-height;

  // @include breakpoint($small){ min-height: $unit-8 + $unit-half; }
  // @include breakpoint($med){ min-height: $unit-6 + $unit-half; }
}

footer {
  margin-bottom: $unit-2;

  @include breakpoint($small) { margin-bottom: $unit-3; }
}

a {
  @include link;
}

button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  font-family: $font-NHG--roman;
  font-size: $h4;
  color: $red;

  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}

img {width: 100%; display: block;}

.container {
  position: relative;
  padding-left:  12px;
  padding-right: 12px;
  margin: 0 auto;
  width: 100%;

  .gridShown &:before {
    position: absolute;
    top: 0;
    left:  12px;
    right: 12px - 1px;
    bottom: 0;
    content: '';
    background: linear-gradient(to right, $col-color 1px, transparent 1px);
    background-size: 8.333333% 1px;

    @include breakpoint($small) {
      left:  24px;
      right: 24px - 1px;
    }

    @include breakpoint(700px) {
      left:  48px;
      right: 48px - 1px;
    }

    @include breakpoint($large) {
      left:  144px;
      right: 144px - 1px;
    }
  }

  @include breakpoint($small) {
    padding-left:  24px;
    padding-right: 24px;
  }

  @include breakpoint(700px) {
    padding-left:  48px;
    padding-right: 48px;
  }

  @include breakpoint($med)   {
    max-width: 1296px;
  }

  @include breakpoint ($large) {
    max-width: 2136px;
    padding-left:  144px;
    padding-right: 144px;
  }
}

.post     { margin-bottom: 12px; }
.heading  { margin-bottom: 24px; }

p {
  margin-bottom: $unit;

  &.h1, .h1-wrap & { margin-bottom: $h1-lh; }
  &.h2, .h2-wrap & { margin-bottom: $h2-lh; }
  // &.h3, .h3-wrap & { margin-bottom: $h3-lh; }
  &.h4, .h4-wrap & { margin-bottom: $h4-lh; }
  &.h5, .h5-wrap & { margin-bottom: $h5-lh; }

  // &.h3--med, .h3--med-wrap & { margin-bottom: $h3-lh; }
  &.h4--med, .h4--med-wrap & { margin-bottom: $h4-lh; }
  &.h5--med, .h5--med-wrap & { margin-bottom: $h5-lh; }
}

blockquote {
  margin-bottom: $unit-3 + 3px;

  p {
    @include h2;
    margin-bottom: 0 !important;
  }

  cite {
    margin-top: 8px;
    margin-bottom: 3px;
    display: block;
    @include h4--med;
    color: $black;
  }
}

section {
  flex: 1;
}
