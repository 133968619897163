.image-gallery {
    @include breakpoint($small) { padding-right: $unit-2; }

    .img.gallery img { position: absolute; width: 100.1% }
}

.gallery-thumbnails {
  padding-right: $unit-half;
  margin-bottom: $unit-2;

  @include breakpoint($small) {
    margin-bottom: 0;
  }
  @extend %row;
  margin-right: -12px !important;
}
.gallery-thumbnails-empty {
  @include breakpoint($mobile) {
    margin-bottom: $unit-3;
  }
}

.gallery-thumbnail {
  margin-top: $unit/2;
  margin-bottom: $unit/2;

  @include breakpoint($mobile) {
    @include col;
    height: 10px;
    width: 10px;
    background-color: #ccc;
    border-radius: 10px;

    margin-top: $unit-half + 2; // grid compensation
    margin-right: $unit-half;
  }
  @include breakpoint($small) {
      @include col-fourth(false);
      padding-right: $unit-half;
      margin-top: $unit;
      margin-bottom: 0;
  }
  @include breakpoint($med) {
      @include col-sixth(false);
      padding-right: $unit-half !important;
  }

  .thumb-wrap {
    position: relative;
    overflow: hidden;
    display: none;

    @include breakpoint($small) { display: block; }
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
      @include breakpoint($mobile) {
        background-color: $grey_dark;
      }
    }
}
