footer .row {
  padding-top: $unit-half;
  @extend %border-top;

  div {
    &:first-child {
      margin-bottom: $unit/2;
    }
  }
  ul {
    div:first-of-type {
      margin-bottom: $unit/2 !important;
    }
  }

  a {
    @include h5;
    @include link;
    display: inline-block;

    &.is-current-page {
      color: $red;
    }
  }
}
