
// Breakpoints

$small:         560px;
$med:           948px;
$large:         1681px;

$mobile:        0px ($small - 1);
$small-only:    0px $med;

$gutter1: 1px; // change for different gutter size
$gutter2: 1px;

$basefont:  17px;

$h1:        46px;
$h2:        36px;
// $h3:        20px;
$h4:        17px;
$h5:        14px;
$h6:        14px;

$h1-lh:        55px;
$h2-lh:        44px;
// $h3-lh:        22px;
$h4-lh:        22px;
$h5-lh:        18px;

// // line height
// $line: $basefont * 1.5;

// spacing
$base-spacing-unit: 22px;

$unit:        11px;

$unit-qtr:    $unit / 4;
$unit-half:   6px; //$unit / 2;

// $unit-15:     $unit * 1.5;
$unit-2:      $unit * 2;
// $unit-25:     $unit * 2.5;
$unit-3:      $unit * 3;
// $unit-35:     $unit * 3.5;
$unit-4:      $unit * 4;
// $unit-45:     $unit * 4.5;
$unit-5:      $unit * 5;
$unit-6:      $unit * 6;
$unit-7:      $unit * 7;
$unit-8:      $unit * 8;
$unit-9:      $unit * 9;
$unit-10:     $unit * 10;
$unit-11:     $unit * 11;
$unit-12:     $unit * 12;
$unit-13:     $unit * 13;

$half-spacing-unit: $base-spacing-unit / 2;
$qtr-spacing-unit:  $base-spacing-unit / 4;


$max-text-width: 760px;

// Colors

$black_absolute:    #000000;
$black:             #222222;
$red:               #ff3c00;
$white:             #fffdfa;
$white_absolute:    #ffffff;
$white_warm:        #fbf5e9;
$grey_dark:         #666666;
$grey_medium:       #999999;
$grey_light:        #cccccc;

// Borders

$body-top-border-height: 17px;

// Font

$font-NHG--roman:       'NeueHaasGroteskText W01', 'helvetica', sans-serif;
$font-NHG--med:         'NHaasGroteskTXW01-65Md', 'helvetica', sans-serif;
$font-NHG--extraLight:  'NHaasGroteskDSW01-35XLt', 'helvetica', sans-serif;
