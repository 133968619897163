.hidden-mobile {
  display: none;
  @include breakpoint($small) {
    display: block;
  }
}

.visible-mobile {
  display: block;
  @include breakpoint($small) {
    display: none;
  }
}

// .title {
//   margin-top: 4px;
//   @include breakpoint($med) {
//     @include col-two-thirds(false);
//   }
//   @include breakpoint($large) {
//     @include col-third(false);
//   }
//   margin-bottom: 30px;
// }

// body > .content:first-of-type {
//   min-height: 100vh;
//   position: absolute;
//   top: 0;
//   padding-top: $unit-2 - 6;
//   padding-bottom: $unit * 16;
//   width: 100vw;

//   @include breakpoint($small){ padding-bottom: $unit-9; }
// }

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  // margin-bottom: 78px;

  // div[class*="blog"] & { // removes margin for all blog pages & posts
  //   margin-bottom: 0;
  // }

  // div[class*="projects"] & { // removes margin for project rool
  //   margin-bottom: 78px - 44px; // subtract thumbnail margin-bottom & line-height
  // }

  // .home & {
  //   margin-bottom: 78px - 12px;

  //   @include breakpoint($large) {
  //     margin-bottom: 78px - 24px;
  //   }
  // }
}

// .section--middle {
//   padding-top: 3px;
//   padding-bottom: 41px;
//   margin-bottom: 4px;
//   border-bottom: 1px solid $black;
//   border-top: 1px solid $black;
// }
