.primary-header {
  margin-top: $unit-2 - $body-top-border-height - 1; //$unit-half;
}

.logo {
  margin-bottom:  $half-spacing-unit;

  @include col-half;

  a {
    display: block;
    width: 168px;
    max-width: 100%;
  }

  svg { // logo svg
    width: 168px;
    max-width: 100%;
  }
}

.nav--header {
  @include o(green);
  @include col-half;
  float:right;
  padding: 0; // for nested elements

  @include breakpoint($med) {
    float:right;
    padding: 0; // for nested elements
  }

  ul {
    position: relative;
  }

  li {
    max-width: 0; white-space: nowrap;
    @include breakpoint($med) {
      @include col-third(false);
      max-width: 100%;
    }
  }

  li:nth-of-type(3) {
    @include breakpoint($small) { position: absolute; top: 0; left: 50%; }
    @include breakpoint($med) { position: relative; top: auto; left: auto; }
  }

  a { // this is attached to product detail
    display: block;
    @include h4--med;
    @include link;
  }
}
