.our-approach .content {
  .bottom_body {
    margin-bottom: $unit-5;

    ul {
      li {
        @include h2;
        &:nth-child(3) { margin-top: $unit*2; }
      }
    }

    @include breakpoint($med){ margin-bottom: $unit-7; }

    // not future-proof, but targets the links easily
    // also non very beautiful, but it works without touching the cms
    @include breakpoint($mobile){
      ul>li:nth-child(1)>a { visibility: hidden; white-space: nowrap; display: block; width: 200px; overflow-x: hidden; }
      ul>li:nth-child(1)>a:after { 
        content: "Email us"; 
        visibility: visible;
        @include link--secondary;
        position: absolute; left: 0;
      }
      ul>li:nth-child(1):hover>a:after {
        background-image: linear-gradient(#fff 50%, $black 50%) !important; 
        color: $black;
      }
  
      ul>li:nth-child(2)>a { visibility: hidden; white-space: nowrap; display: block; width: 200px; overflow-x: hidden; }
      ul>li:nth-child(2)>a:after { 
        content: "Call us"; 
        visibility: visible;
        @include link--secondary;
        position: absolute; left: 0;
      }
      ul>li:nth-child(2):hover>a:after {
        background-image: linear-gradient(#fff 50%, $black 50%) !important; 
        color: $black;
      }
    }
  }

  .post-img {
    img {
      position: absolute;
    }
  }
}
