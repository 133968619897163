@mixin h1 {
  font-size: $h1;
  line-height: $h1-lh;
  font-family: $font-NHG--extraLight;
  letter-spacing: -.5px;
  position: relative;
  top: -7px;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

@mixin h2 {
  font-size: $h2;
  line-height: $h2-lh;
  font-family: $font-NHG--extraLight;
  color: $grey_dark;
  position: relative;
  top: -9px;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

// @mixin h3 {
//   font-size: $h3;
//   line-height: $h3-lh;
//   font-family: $font-NHG--roman;
//   letter-spacing: -.3px;
//   position: relative;
//   top: -4px;

//   @include breakpoint($small) {}
//   @include breakpoint($med) {}
//   @include breakpoint($large) {}
// }

// @mixin h3--med {
//   @include h3;
//   font-family: $font-NHG--med;

//   @include breakpoint($small) {}
//   @include breakpoint($med) {}
//   @include breakpoint($large) {}
// }

@mixin h4 {
  font-size: $h4;
  line-height: $h4-lh;
  font-family: $font-NHG--roman;
  letter-spacing: -.3px;
  position: relative;
  top: -4px;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

@mixin h4--med {
  @include h4;
  font-family: $font-NHG--med;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

@mixin h5 {
  font-size: $h5;
  line-height: $h5-lh;
  font-family: $font-NHG--roman;
  position: relative;
  top: -3px;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

@mixin h5--med {
  @include h5;
  font-family: $font-NHG--med;

  @include breakpoint($small) {}
  @include breakpoint($med) {}
  @include breakpoint($large) {}
}

h1, .h1, .h1-wrap p, .h1-wrap a { @include h1; }
h2, .h2, .h2-wrap p, .h2-wrap a { @include h2; }
// h3, .h3, .h3-wrap p, .h3-wrap a { @include h3; }
h4, .h4, .h4-wrap p, .h4-wrap a { @include h4; }
h5, .h5, .h5-wrap p, .h5-wrap a { @include h5; }

// .h3--med, .h3--med-wrap p, .h3--med-wrap a { @include h3--med; }
.h4--med, .h4--med-wrap p, .h4--med-wrap a { @include h4--med; }
.h5--med, .h5--med-wrap p, .h5--med-wrap a { @include h5--med; }

@mixin focusBox {
  //display: inline-block;
  //position: relative;
  outline: 0;

  /*&:focus {
    &:after {
      content: '';
      position: absolute;
      top:      -.05em;
      bottom:   -.1em;
      left:     -.3em;
      right:    -.3em;
      border: 1px solid $grey_medium;
    }
  }*/
}

@mixin link {
  text-decoration: none;

  &:hover,
  &:active {
    color: $red;
  }
}

@mixin link--secondary {
  @include focusBox;
  display: inline;
  text-decoration: none;
  background-image: linear-gradient(#fff 50%, $grey_dark 50%);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 2px 2px;
  color: $grey_dark;

  &:hover,
  &:active {
    background-image: linear-gradient(#fff 50%, $black 50%);
    color: $black;
  }
}

@mixin link--tertiary {
  text-decoration: none;
  background-repeat: repeat-x;
  background-position: 0 1em;
  background-size: 2px 2px;
  color: $red;

  &:hover,
  &:active {
    background-image: linear-gradient(#fff 50%, $red 50%);
  }
}

@mixin link--reset {
  text-decoration: none;
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
}

.link--reset      { @include link--reset; }
.link--secondary  { @include link--secondary; }
.link--tertiary   { @include link--tertiary; }

.h2 a, .h2-wrap a { top: auto; @include link--secondary; }
.h4 a, .h4-wrap a { top: auto; @include link--tertiary; }
.h5 a, .h5-wrap a { top: auto; @include link--tertiary; }

.h4--med a, .h4--med-wrap a { top: auto; @include link--tertiary; }
.h5--med a, .h5--med-wrap a { top: auto; @include link--tertiary; }


// $FIXME - remove everything below
// .bold,
// %bold { // $fix turn into bold style
//   @include h4--med;

//   @include breakpoint($large) {
//     @include h3--med;
//   }
// }
